import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import BookingsIcon from "../../assets/img/icons/menu/calendar.svg";
import BarChartIcon from "../../assets/img/icons/menu/bar-chart.svg";
import SchoolIcon from "../../assets/img/icons/menu/school.svg";
import PastIcon from "../../assets/img/icons/menu/past.svg";
import SearchIcon from "../../assets/img/icons/menu/search.svg";
import RequestIcon from "../../assets/img/icons/menu/lightbulb_hourglass.svg";
import CurriculumIcon from "../../assets/img/icons/menu/search_curriculum.svg";
import CollectionsIcon from "../../assets/img/icons/menu/collections.svg";
import FavouritesIcon from "../../assets/img/icons/menu/favourites.svg";
import EventsIcon from "../../assets/img/icons/menu/users.svg";
import ContactIcon from "../../assets/img/icons/menu/contacts.svg";
import InfoIcon from "../../assets/img/icons/menu/user.svg";
import SessionsIcon from "../../assets/img/icons/menu/lightbulb_calendar.svg";
import PoliciesIcon from "../../assets/img/icons/menu/papers.svg";
import PracticesIcon from "../../assets/img/icons/menu/award.svg";
import ResourcesIcon from "../../assets/img/icons/menu/resources.svg";
import TourIcon from "../../assets/img/icons/menu/platform_tour.svg";
import PDPlanIcon from "../../assets/img/icons/menu/pd-plan.svg";
import { getIsPDPlanUser } from "../../pages/PDPlan/util/get-is-pd-plan-user";

export const useSidebar = () => {
  const { userType, userInfo, isSessionHost } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const [navs, setNavs] = useState([]);
  const { startTour, show } = useIntercom();

  useEffect(() => {
    setNavs(getNavigations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, userType])

  const getNavigations = () => {
    switch (userType) {
      case "Teacher":
        return getTeacherNavs();
      case "Team":
        return getTeamNavs();
      case "Provider":
        return getProviderNavs();
      case "Presenter":
        return getPresenterNavs();
      default:
        return [];
    }
  }

  const getTeacherNavs = () => {
    const result = [
      {
        to: "/dashboard",
        label: t("dashboard"),
        labelInMobile: t("dashboard"),
        iconImg: BarChartIcon,
        intercomLabel: "dashboard"
      },
      {
        to: "/upcoming",
        label: t("my-sessions"),
        labelInMobile: "Sessions",
        iconImg: BookingsIcon,
        intercomLabel: "my-sessions"
      },
    ];

    if (userInfo["School Leader?"] || userInfo["District Leader?"]) {
      let title = "", mTitle = ""
      if (
        (
          Array.isArray(userInfo["Schools Managed"]) &&
          userInfo["Schools Managed"].length > 0
        ) || (
          Array.isArray(userInfo["School Name"]) &&
          userInfo["School Name"].length > 1
        ) || userInfo["District Leader?"]
      ) {
        title = t("principal.heading-multi");
        mTitle = t("principal.m-heading-multi");
      } else {
        title = t("principal.heading");
        mTitle = t("principal.m-heading");
      }
      result.push({
        to: "/principal",
        label: title,
        labelInMobile: mTitle,
        iconImg: SchoolIcon,
        intercomLabel: "principal.heading"
      })
    }

    if (userInfo["Limit Booking to Collection"] && userInfo["Limit Booking to Collection"].length) {
      result.push({
        to: "/collection/" + userInfo["Limit Booking to Collection"][0],
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: PastIcon,
        intercomLabel: "search"
      })
    } else {
      result.push({
        to: "/search",
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: SearchIcon,
        intercomLabel: "search"
      })
    }

    result.push({
      to: "/pending",
      label: t("requests"),
      labelInMobile: "Requests",
      tooltip: t("pending_session_requests"),
      iconImg: RequestIcon,
      intercomLabel: "requests"
    });

    if (userInfo["Preferred Language"] !== 'French') {
      result.push({
        to: "/browser/curriculum",
        label: t("explore-by-curriculum"),
        labelInMobile: "Curriculum",
        iconImg: CurriculumIcon,
        intercomLabel: "explore-by-curriculum"
      });
      result.push({
        to: "/collection",
        label: t("explore-by-collection"),
        labelInMobile: "Collections",
        iconImg: CollectionsIcon,
        intercomLabel: "explore-by-collection"
      });
    }
    
    result.push({
      to: "/favorites",
      label: t("menu-favourites"),
      labelInMobile: t("menu-favourites"),
      tooltip: t("my-favourites"),
      iconImg: FavouritesIcon,
      intercomLabel: "menu-favourites"
    });
    result.push({
      to: "/events",
      label: t("events"),
      labelInMobile: "Events",
      iconImg: EventsIcon,
      intercomLabel: "events"
    });

    if (getIsPDPlanUser(userInfo)) {
      result.push({
        to: "/pd-plan",
        label: t("my-pd-plan"),
        iconImg: PDPlanIcon,
        labelInMobile: "PD Plan"
      });
    }

    result.push({
      to: "/mysettings",
      label: t("my-settings"),
      labelInMobile: "Settings",
      svg: "setting",
      intercomLabel: "my-settings"
    })

    result.push({
      to: "/dashboard?product_tour_id=458667",
      label: t("platform-tour"),
      labelInMobile: "Tour",
      iconImg: TourIcon,
      containerClass: "platform-tour",
      onClick: () => startTour(458667),
      intercomLabel: "platform-tour"
    })

    result.push({
      label: t("help-and-faqs"),
      labelInMobile: "Help",
      svg: "faqs",
      onClick: () => show(),
      intercomLabel: "help-and-faqs"
    })

    return result;
  }

  const getTeamNavs = () => {
    if (isSessionHost && !userInfo["Host Training Approved"]) {
      return [{
        to: "/session-host/best-practices/introduction",
        label: "Host Training",
        iconImg: FavouritesIcon,
        intercomLabel: "Host Training"
      }];
    }

    const result = [
      {
        to: "/upcoming",
        label: t("upcoming"),
        labelInMobile: "Upcoming",
        tooltip: t("upcoming_sessions"),
        iconImg: BookingsIcon,
        intercomLabel: "upcoming_sessions"
      },
    ];
    if (!isSessionHost) {
      result.push({
        to: "/rate",
        label: t("manage-past"),
        labelInMobile: "Past",
        tooltip: t("manage-past-sessions"),
        iconImg: PastIcon,
        intercomLabel: "manage-past"
      })
      result.push({
        to: "/search",
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: SearchIcon,
        intercomLabel: "search"
      })
    }

    if (!isSessionHost && userInfo["Schools Managed"] && userInfo["Schools Managed"].length > 0) {
      let label = "", mLabel = ""
      if (
        (Array.isArray(userInfo["Schools Managed"]) &&
          userInfo["Schools Managed"].length > 0
        ) || (
          Array.isArray(userInfo["School Name"]) &&
          userInfo["School Name"].length > 1
        ) || userInfo["District Leader?"]
      ) {
        label = t("principal.heading-multi");
        mLabel = t("principal.m-heading-multi");
      } else {
        label = t("principal.heading");
        mLabel = t("principal.m-heading");
      }
      result.push({
        to: "/principal",
        label: label,
        labelInMobile: mLabel,
        iconImg: SchoolIcon,
        intercomLabel: "principal"
      })
    }

    if (isSessionHost) {
      result.push({
        to: "/session-host/best-practices/introduction",
        label: "Host Training",
        labelInMobile: "H-Traning",
        iconImg: FavouritesIcon,
        intercomLabel: "Host Training"
      });
      if (userInfo["Host Email"] === 'mfurdyk') {
        result.push({
          to: "/available-sessions",
          label: "Available Sessions",
          labelInMobile: "Availables",
          iconImg: EventsIcon,
          intercomLabel: "Available Sessions"
        });
      }
      result.push({
        to: "/session-host/availability",
        label: "My Availability",
        labelInMobile: "Availability",
        iconImg: InfoIcon
      });
      if (userInfo["Host Email"]) {
        result.push({
          to: "https://mail.google.com/a/connectednorth.org",
          label: "My E-mail",
          labelInMobile: "E-mail",
          iconImg: ContactIcon,
          openInNewTab: true
        });
      }
    }

    if (!isSessionHost) {
      result.push({
        to: "/pending",
        label: t("requests"),
        labelInMobile: "Requests",
        tooltip: t("pending_session_requests"),
        iconImg: RequestIcon,
        intercomLabel: "requests"
      });
      result.push({
        to: "/browser/curriculum",
        label: t("explore-by-curriculum"),
        labelInMobile: "Curriculum",
        iconImg: CurriculumIcon,
        intercomLabel: "explore-by-curriculum"
      });
      result.push({
        to: "/collection",
        label: t("explore-by-collection"),
        labelInMobile: "Collections",
        iconImg: CollectionsIcon,
        intercomLabel: "explore-by-collection"
      });
      result.push({
        to: "/contact",
        label: t("contacts"),
        labelInMobile: "Contacts",
        iconImg: ContactIcon,
        intercomLabel: "contacts"
      });
      result.push({
        to: "/mysettings",
        label: t("my-settings"),
        labelInMobile: "Settings",
        svg: "setting",
        intercomLabel: "my-settings"
      })
      result.push({
        to: "/events",
        label: t("events"),
        labelInMobile: "Events",
        iconImg: EventsIcon,
        intercomLabel: "events"
      });
    }
    return result;
  }

  const getProviderNavs = () => {
    const result = [
      {
        to: "/upcoming",
        label: t("my-bookings"),
        labelInMobile: "Bookings",
        tooltip: t("upcoming_sessions"),
        iconImg: BookingsIcon,
        intercomLabel: "my-bookings"
      },
      {
        to: "/pending",
        label: t("requests"),
        labelInMobile: "Requests",
        tooltip: t("pending_session_requests"),
        iconImg: RequestIcon,
        intercomLabel: "requests"
      },
      {
        to: "/mysessions",
        label: t("my-sessions"),
        labelInMobile: "Sessions",
        tooltip: t("search-my-sessions"),
        iconImg: SessionsIcon,
        intercomLabel: "my-sessions"
      },
      {
        to: "/policies",
        label: "Policies",
        labelInMobile: "Policies",
        iconImg: PoliciesIcon,
        intercomLabel: "Policies"
      },
      {
        to: "/best-practices/introduction",
        label: t("best-practices"),
        labelInMobile: "Practices",
        iconImg: PracticesIcon,
        intercomLabel: "best-practices"
      },
      {
        to: "/resources",
        label: t("resources-title"),
        iconImg: ResourcesIcon,
        labelInMobile: "Resources",
        intercomLabel: "resources-title"
      },
      {
        to: "/upcoming?product_tour_id=466709",
        label: t("platform-tour"),
        labelInMobile: "Tour",
        iconImg: TourIcon,
        containerClass: "platform-tour",
        intercomLabel: "platform-tour"
      },
      {
        to: "/myinfo",
        label: t("my-info"),
        labelInMobile: t("my-info"),
        tooltip: t("view-edit-contact-information"),
        iconImg: InfoIcon,
        intercomLabel: "my-info"
      },
    ]

    return result;
  }

  const getPresenterNavs = () => {
    const result = [
      {
        to: "/upcoming",
        label: t("my-bookings"),
        labelInMobile: "Bookings",
        tooltip: t("upcoming_sessions"),
        iconImg: BookingsIcon,
        intercomLabel: "my-bookings"
      },
      {
        to: "/best-practices/introduction",
        label: t("best-practices"),
        labelInMobile: t("best-practices"),
        iconImg: PracticesIcon,
        intercomLabel: "best-practices"
      },
      {
        to: "/myinfo",
        label: t("my-info"),
        labelInMobile: t("my-info"),
        tooltip: t("view-edit-contact-information"),
        iconImg: InfoIcon,
        intercomLabel: "my-info"
      },
      {
        to: "/resources",
        label: t("resources-title"),
        labelInMobile: "Resources",
        iconImg: ResourcesIcon,
        intercomLabel: "resources-title"
      },
    ]

    return result;
  }

  return {
    navs
  }
}
