import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  InputGroupText,
  InputGroup,
  Input
} from 'reactstrap'
import ReactDatetime from 'react-datetime';
import airtable from '../../../airtables';
import Loading from '../../../components/Loading.js';
import { toast } from 'react-toastify';
import { logEvent } from 'firebase/analytics';
import {
  DAY_LIMITATIONS_MAP,
  GradeLevels,
  IS_WISHALL
} from '../../../utils/constants';
import {
  isSameDay,
  isValidDateOnPicker
} from '../../../utils/time';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analytics } from '../../../firebase';
import './styles.scss';
import {
  getDefaultAlternativeTime,
  getDefaultPreferredTime,
} from './utils.js';
import Select from 'react-select';
import SessionValidationChecker from '../../../components/SessionValidationChecker/index.js';

const RequestForm = ({
  session,
  onToggle,
  onSubmitted,
  edit,
  requestedSession,
  dayRestrictionConfig,
  suppliesOption,
}) => {
  const labelForMe = "No, this request is for my class";
  const sessionLen = session["Length (Minutes)"];
  const provider = session["Provider"]
  const { userType, appLocale, userInfo } = useSelector(state => state.appInfo);

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [alterDate, setAlterDate] = useState(null);
  const [notes, setNotes] = useState("");
  const [school, setSchool] = useState(null);
  const [grades, setGrades] = useState(GradeLevels.map(item => ({ name: item, checked: false })));
  const [students, setStudents] = useState();
  const [dayRestriction, setDayRestriction] = useState(dayRestrictionConfig ? dayRestrictionConfig : 14);
  const [preferredTimeChanged, setPreferredTimeChanged] = useState(false);
  const [dayLimitations, setDayLimitations] = useState([]);
  const [providerData, setProviderData] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(userInfo);
  const [isStartTimeValid, setIsStartTimeValid] = useState("");
  const [isAlterTimeValid, setIsAlterTimeValid] = useState("");
  const isSchoolManager = userType === "Teacher" && (userInfo["In-school Coordinator"] || userInfo["School Leader?"])
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      const providerInfo = await airtable.providers.select(session["Provider"][0]);
      setProviderData(providerInfo);

      let dl = [];
      if (providerInfo["Day Limitations"]) {
        dl = providerInfo["Day Limitations"].map(l => DAY_LIMITATIONS_MAP[l])
      }
      if (edit && requestedSession) {
        setStartDate(new Date(requestedSession["Session Start Date/Time"]));
        setAlterDate(new Date(requestedSession["Alternative Date/Time"]));
        setNotes(requestedSession["Notes"]);
        setStudents(requestedSession["Students"]);
        setPreferredTimeChanged(true);

        var gs = [];
        GradeLevels.forEach((grade, i) => {
          gs.push({
            name: grade,
            checked: (requestedSession["Grade(s)"] ? requestedSession["Grade(s)"].indexOf(grade) >= 0 : false)
          })
        })
        setGrades(gs);
      } else {
        setStartDate(getDefaultPreferredTime(dayRestrictionConfig, session["Provider Advance Booking Requirement"], dl));
        setAlterDate(getDefaultAlternativeTime(dayRestrictionConfig, session["Provider Advance Booking Requirement"], dl));
      }
      setDayLimitations(dl);

      if (userType === 'Teacher') {
        if (session["Provider Advance Booking Requirement"] && !dayRestrictionConfig) {
          setDayRestriction(session["Provider Advance Booking Requirement"][0])
        }
        const school = await airtable.schools.select(userInfo['School Name'][0]);
        setSchool(school);
      }
      if (userType === 'Team') {
        setDayRestriction(0);
      } else if (isSchoolManager) {
        let allTeachers = [];
        if (Array.isArray(userInfo["School Name Text"])) {
          for (const schoolName of userInfo["School Name Text"]) {
            const schoolTeachers = await airtable.teachers.getSchoolTeachers(schoolName);
            schoolTeachers.forEach(t => {
              t.displayName = `${t["Teacher Name"]} (${schoolName})`;
            });
            allTeachers = [...allTeachers, ...schoolTeachers];
          }
        } else {
          const ts = await airtable.teachers.getSchoolTeachers(userInfo["School Name Text"][0]);
          allTeachers = ts;
        }
        allTeachers.sort((a, b) => {
          const nameA = a.displayName || a["Teacher Name"];
          const nameB = b.displayName || b["Teacher Name"];
          return nameA.localeCompare(nameB);
        });
        setTeachers(allTeachers.filter(t => t.id !== userInfo.id));
      }
    }
    init();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onDone = async () => {
    setLoading(true);
    if (userType !== 'Team' && userType !== 'Teacher') {
      setLoading(false);
      return;
    }

    if (!selectedTeacher) return;

    const sessionLength = session["Length (Minutes)"] || 60;

    const calcDiffDays = (date1, date2) => {
      if (typeof date1 === 'string') date1 = new Date(date1);
      if (typeof date2 === 'string') date2 = new Date(date2);

      var diff = date1.getTime() - date2.getTime();

      var diffDays = diff / (1000 * 3600 * 24);
      return diffDays
    }

    if (!preferredTimeChanged) {
      setLoading(false);
      alert(t("sessionrequest.confirm-msg"));
      return;
    }

    if (!students || students <= 0) {
      setLoading(false);
      alert(t("sessionrequest.students-num-msg"));
      return;
    }

    const gap1 = calcDiffDays(startDate, new Date());
    if (gap1 < dayRestriction) {
      setLoading(false);
      alert(t("sessionrequest.day-restrict-msg", { num: dayRestriction }));
      return;
    }

    const gap2 = calcDiffDays(alterDate, new Date());
    if (gap2 < dayRestriction) {
      setLoading(false);
      alert(t("sessionrequest.day-restrict-msg", { num: dayRestriction }));
      return;
    }

    let requestGrades = [];
    grades.forEach((grade) => {
      if (grade.checked) requestGrades.push(grade.name);
    })

    if (requestGrades.length === 0) {
      setLoading(false);
      alert(t("sessionrequest.grades-msg"));
      return;
    }

    /* check whether there are sessions for this provider in booked period */
    if (!edit) {
      const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
        startDate,
        sessionLength,
        'Provider',
        session["Provider Name Text"][0],
        session["Provider"][0]
      );
      if (sessions.length) {
        setLoading(false);
        alert("Sorry, the provider is already scheduled for a Connected North session at this time. Please try another date or time!");
        return;
      }
    } else {
      const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
        startDate,
        sessionLength,
        'Provider',
        session["Provider Name"][0],
        session["Provider"][0]
      );

      if (sessions.length > 1 || (sessions.length === 1 && sessions[0].id !== session.id)) {
        setLoading(false);
        alert("Sorry, the provider is already scheduled for a Connected North session at this time. Please try another date or time!");
        return;
      }
    }

    /* check whether there are sessions for this teacher in booked period */
    if (!edit) {
      const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
        startDate,
        sessionLength,
        'Teacher',
        selectedTeacher["Teacher Name"],
        selectedTeacher.id
      );

      if (sessions.length) {
        setLoading(false);
        alert("Oops! the teacher is already scheduled for or requested another Connected North session during this same time. Please try another date or time!");
        return;
      }
    } else {
      const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
        startDate,
        sessionLength,
        'Teacher',
        selectedTeacher["Teacher Name"],
        selectedTeacher.id
      );

      if (sessions.length > 1 || (sessions.length === 1 && sessions[0].id !== session.id)) {
        setLoading(false);
        alert("Sorry, the teacher is already scheduled for a Connected North session at this time. Please try another date or time!");
        return;
      }
    }

    // Is this a request, or adding to a wishlist based on Credits available?
    var creditCalc;
    var setStatus;
    var wishlisted;
    var allowFree;
    const providerGroups = session["Indigenous Group(s)"];
    const schoolGroups = selectedTeacher["School Indigenous Group(s)"];
    if (providerGroups && schoolGroups && Array.isArray(providerGroups) && providerGroups.length > 0 && providerGroups.some(group => schoolGroups.includes(group))) {
      creditCalc = selectedTeacher['Credit Balance'] - session['Discounted Credits'];
      if (session['Discounted Credits'] === 0) {
        allowFree = true;
      }
    } else {
      creditCalc = selectedTeacher['Credit Balance'] - session['Credits'];
      if (session['Credits'] === 0) {
        allowFree = true;
      }
    }
    if (creditCalc >= 0 || allowFree) {
      if (IS_WISHALL) {
        setStatus = 'Wish List';
        wishlisted = false;
      } else {
        setStatus = 'Requested';
        wishlisted = false;
      }
    } else {
      setStatus = 'Wish List';
      wishlisted = true;
    }

    var param;
    if (edit) {
      param = {
        'Session Start Date/Time': startDate,
        'Alternative Date/Time': alterDate,
        'Notes': notes,
        'Grade(s)': requestGrades,
        'Students': parseInt(students)
      }

      airtable.sessions.update(requestedSession.id, param).then(async () => {
        logEvent(
          analytics,
          'update_request_session',
          { id: requestedSession.id }
        )
        toast.success(t("sessionrequest.request-updated-msg"));
        onSubmitted();
      }).catch(error => {
        toast.error(error.toString())
      }).finally(() => setLoading(false))
    } else {
      param = {
        'Session Start Date/Time': startDate,
        'Alternative Date/Time': alterDate,
        'Notes': notes,
        'Status': setStatus,
        'Teacher': [selectedTeacher.id],
        'Cost': session['Cost'],
        'Session Title': [session.id],
        'Subject/Curriculum': session['Subject'],
        'Well-being Link': session['Well-being Link'],
        'Provider': provider,
        'School(s)': selectedTeacher['School Name'],
        'School Lead': school['TIG School Lead'],
        'Type of Session': session['Type of Session'],
        'Length (Minutes)': sessionLen,
        'Self-Requested through Platform': true,
        'Wishlisted': wishlisted,
        'Grade(s)': requestGrades,
        'Students': parseInt(students),
        'Presenters': session['Default Presenter'] || [],
        'Send Meeting Invite to:': 'All'
      }

      const sessionData = await airtable.providerSessions.select(session.id);
      if (sessionData && sessionData["Tied Session"]) {
        param['Session Title'] = param['Session Title'] ?
          [...param['Session Title'], ...sessionData["Tied Session"]] :
          sessionData["Tied Session"];

        param['Provider'] = param['Provider'] ?
          [...param['Provider'], ...sessionData["Tied Session Provider"]] :
          sessionData["Tied Session Provider"];
      }

      if (selectedTeacher.id !== userInfo.id) {
        param["Coordinating Teacher"] = [userInfo.id];
      }

      if (suppliesOption.startsWith("not-required")) {
        param["Supplies Shipment Not Required"] = true;
      }

      setLoading(true);
      airtable.sessions.create(param).then(async res => {
        logEvent(
          analytics,
          'request_session',
          { id: res.id }
        );
        toast.success(t("sessionrequest.request-received-msg"))
        onSubmitted();
      }).catch(error => {
        console.log("error", error)
        toast.error(error.toString())
      }).finally(() => setLoading(false))
    }
  }

  const showSameDayWarning = () => {
    if (isSameDay(startDate, alterDate)) {
      toast.warning(t("sessionrequest.second-date-msg"), 10000);
    }
  }

  const getDayLimitationString = () => {
    const limitations = providerData["Day Limitations"];
    let translatedDays = limitations;

    if (userInfo["Preferred Language"] === "French") {
      const englishToFrench = {
        Mondays: "lundis",
        Tuesdays: "mardis",
        Wednesdays: "mercredis",
        Thursdays: "jeudis",
        Fridays: "vendredis"
      };
      translatedDays = limitations.map(day => englishToFrench[day] || day);
    }
    switch (limitations.length) {
      case 0:
        return "";
      case 1:
        return translatedDays[0];
      default:
        const subs = translatedDays.slice(0, translatedDays.length - 1);
        const last = translatedDays[translatedDays.length - 1];
        return subs.join(", ") + (userInfo["Preferred Language"] === "French" ? " et " : " and ") + last;
    }
  }

  const canSave = () => {
    if (loading) return false;
    if (isStartTimeValid !== "valid") return false;
    if (isAlterTimeValid !== "valid") return false;
    return true;
  }

  const creditCheck = (edit) => {
    if (!selectedTeacher) return null;

    var creditCalc;
    var creditCost;
    const providerGroups = session["Indigenous Group(s)"];
    const schoolGroups = selectedTeacher["School Indigenous Group(s)"];
    const tName = selectedTeacher.id !== userInfo.id ? `${selectedTeacher["Teacher Name"]}'s` : "My";

    if (suppliesOption.startsWith("not-required")) {
      creditCost = parseFloat(suppliesOption.slice(13));
    } else if (
      providerGroups &&
      schoolGroups &&
      Array.isArray(providerGroups) &&
      providerGroups.length > 0 &&
      providerGroups.some(group => schoolGroups.includes(group))
    ) {
      creditCost = session['Discounted Credits'];
    } else {
      creditCost = session['Credits'];
    }

    creditCalc = selectedTeacher['Credit Balance'] - creditCost;

    if (edit) {
      return (
        <div className="credits">
          <Button
            color="primary"
            type="button"
            disabled={!canSave()}
            onClick={() => onDone()}
          >{loading && (<Loading size={14} />)}{userType === 'Team' ? t("edit-session") : t("edit-request")}</Button>
          <Button
            data-dismiss="modal"
            color="outline-primary"
            type="button"
            onClick={onToggle}
          >{t("cancel")}</Button>
        </div>
      )
    } else if (creditCalc >= 0 || creditCost === 0) {
      return (
        <div className="credits">
          <div className='credit-status'>
            <span className='text-left'>
              {t("available-credits", { name: tName })}: {selectedTeacher['Credit Balance']}<br></br>
              {t("session-credits")}: {creditCost}<br></br>
              {selectedTeacher["Preferred Language"] === 'French' && (
                <>{t("remaining-credits")}: {creditCalc}</>
              )}
            </span>
            {selectedTeacher["Preferred Language"] !== 'French' && (
              <>
                <span>
                  {t("remaining-credits")}: {creditCalc}
                </span>
              </>
            )}
          </div>
          <Button
            color="primary"
            type="button"
            disabled={!canSave()}
            onClick={() => onDone()}
          >{loading && (<Loading size={14} />)}{t("submit-request")}</Button>
          <Button
            data-dismiss="modal"
            color="outline-primary"
            type="button"
            onClick={onToggle}
          >{t("cancel")}</Button>
        </div>
      )
    }

    return (
      <div className='credits-insufficient'>
        <div className='credit-status'>
          {t("insufficient-credits", { total: selectedTeacher['Credit Balance'], session: creditCost })}
          <br />However, we invite you to request this Session as a Wish List booking.
          <br />We'll note this for possible future booking, pending funding availability.
        </div>
        <Button
          color="primary"
          type="button"
          disabled={!canSave()}
          onClick={() => onDone()}
        >{loading && (<Loading size={14} />)}{t("sessionrequest.submit")} Wish List {t("request")}</Button>
        <Button
          data-dismiss="modal"
          color="outline-primary"
          type="button"
          onClick={() => window.open(`/cn/credits/earn-more`)}
        >{t("learn-earn-credits")}</Button>
      </div>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={onToggle}
      size='lg'
    >
      <div className="modal-header" style={{ paddingTop: '2.5rem', paddingBottom: 0 }}>
        <h3 className="modal-title" id="modal-title-default">
          {edit ? t("edit-request-for") : t("request")}
          &nbsp;
          '{session['Session Title Text']
            ? session['Session Title Text']
            : session['Session Title']}'
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body" style={{ marginBottom: 0 }}>
        {(!!providerData && dayLimitations.length > 0) && (
          <div className="day-limitations-msg">
            <span>{t("sessionrequest.day-of-week-restrict-msg", { providername: providerData.Name, daylimitation: getDayLimitationString() })}</span>
          </div>
        )}
        {(!!providerData && providerData['Other Limitations']) && (
          <div className="day-limitations-msg">
            <span>{providerData['Other Limitations']}</span>
          </div>
        )}
        <div className="">
          {isSchoolManager && (
            <FormGroup>
              <label>Is this request for another Teacher at your School?</label>
              <Select
                className="form-style"
                placeholder="Select a teacher"
                value={selectedTeacher ? {
                  value: selectedTeacher.id,
                  label: (selectedTeacher.id === userInfo.id)
                    ? labelForMe
                    : selectedTeacher["Teacher Name"]
                } : null}
                onChange={(selectedOption) => {
                  if (selectedOption.value === userInfo.id) {
                    setSelectedTeacher(userInfo);
                  } else {
                    const t = teachers.find(ti => ti.id === selectedOption.value);
                    setSelectedTeacher(t);
                  }
                }}
                options={[
                  { value: userInfo.id, label: labelForMe },
                  ...(teachers.map(t => ({
                    value: t.id,
                    label: t.displayName || t["Teacher Name"]
                  })))
                ]}
              />
            </FormGroup>
          )}
          <FormGroup>
            <label>{t("sessionrequest.prefer-time")}</label>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
              {!!startDate && (
                <ReactDatetime
                  value={startDate}
                  inputProps={{
                    placeholder: t("sessionrequest.prefer-time")
                  }}
                  timeConstraints={{
                    minutes: { step: 5 }
                  }}
                  isValidDate={(currentDate) => isValidDateOnPicker(dayLimitations, currentDate, dayRestriction)}
                  timeFormat={true}
                  onChange={(e) => {
                    if (typeof e !== 'string') {
                      const selectedDate = e.toDate();
                      setStartDate(selectedDate);
                      showSameDayWarning();
                      setPreferredTimeChanged(true);
                    }
                  }}
                />
              )}
            </InputGroup>
            {!!session && !!providerData && !!selectedTeacher && (
              <SessionValidationChecker
                time={startDate}
                session={session}
                provider={providerData}
                teachers={selectedTeacher}
                bookedSessionId={edit ? requestedSession.id : null}
                changeValidation={v => setIsStartTimeValid(v)}
              />
            )}
          </FormGroup>

          <FormGroup>
            <label>{t("sessionrequest.alternative-time")}</label>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
              {!!alterDate && (
                <ReactDatetime
                  value={alterDate}
                  inputProps={{
                    placeholder: t("sessionrequest.alternative-time")
                  }}
                  timeConstraints={{
                    minutes: { step: 5 }
                  }}
                  isValidDate={(currentDate) => isValidDateOnPicker(dayLimitations, currentDate, dayRestriction)}
                  timeFormat={true}
                  onChange={(e) => {
                    if (typeof e !== 'string') {
                      const selectedDate = e.toDate();
                      setAlterDate(selectedDate);
                      showSameDayWarning();
                    }
                  }}
                />
              )}
            </InputGroup>
            {!!session && !!providerData && !!selectedTeacher && (
              <SessionValidationChecker
                time={alterDate}
                session={session}
                provider={providerData}
                teachers={selectedTeacher}
                bookedSessionId={edit ? requestedSession.id : null}
                changeValidation={v => setIsAlterTimeValid(v)}
              />
            )}
          </FormGroup>

          <FormGroup>
            <div className="multicheck-container">
              <label>{t("grade-levels")}</label>
              <div className="multicheck">
                {grades.map((grade, i) => (
                  <div
                    key={i}
                    className={grade.checked ? "multicheck-item item-checked" : "multicheck-item"}
                    onClick={() => {
                      const gs = [...grades];
                      gs[i].checked = !gs[i].checked;
                      setGrades(gs);
                    }}
                  >
                    <span>
                      {
                        (grade.name === "Professional Development" && appLocale === 'fr')
                          ? "Développement professionnel"
                          : (grade.name === "After School" && appLocale === 'fr')
                            ? "Activité parascolaire"
                            : grade.name
                      }
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </FormGroup>

          <FormGroup>
            <label>{t("sessionrequest.number-of-students")}</label>
            <Input
              defaultValue={students}
              onChange={(e) => setStudents(e.target.value)}
              type="number"
            />
          </FormGroup>

          <FormGroup>
            <label>{t("sessionrequest.notes")}</label>
            <Input
              defaultValue={notes}
              placeholder={t("sessionrequest.notes-msg")}
              rows="2"
              onChange={(e) => setNotes(e.target.value)}
              type="textarea"
            />
          </FormGroup>
        </div>
      </div>
      <div className="modal-footer" style={{ justifyContent: 'center', paddingTop: 0 }}>
        {creditCheck(edit)}
      </div>
    </Modal>
  )
}

export default RequestForm;
