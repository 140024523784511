import { useEffect, useRef, useState } from "react";
import airtable from "../../airtables";
import { checkProviderWorkingTime, getEarliestStartTimeFromProviderSession } from "../../pages/modals/Request/utils";
import Axios from 'axios';
import { distinctArray } from "../../utils/array";

const useHook = ({
  time,
  session,
  provider,
  teachers,
  bookedSessionId
}) => {
  const isCalendarToken = provider['Calendar Token'] ? true : false;
  const providerTimezone = useRef(session["Provider Timezone"][0]).current;
  const earliestStartTime = useRef(getEarliestStartTimeFromProviderSession(session)).current;
  const [checking, setChecking] = useState(false);
  const sessionLength = session["Length (Minutes)"] || 60;
  const checkingRef = useRef(null);
  const [validationData, setValidationData] = useState(null);

  useEffect(() => {
    if (checkingRef.current) {
      checkingRef.current.cancel();
    }
    checkingRef.current = checkValidation();
    setChecking(true);
    checkingRef.current.promise.then((data) => {
      setValidationData(data);
      setChecking(false);
    }).catch(error => { })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, session, provider, teachers]);

  const checkValidation = () => {
    let cancelFunc = () => { };
    const promise = new Promise(async (resolve, reject) => {
      cancelFunc = () => {
        reject();
      }

      const timeValidation = await checkValidationOfSelectedTime();
      if (timeValidation.startsWith("invalid")) {
        resolve({
          vTime: timeValidation,
        })
      }

      const providerValidation = await checkValidationForProvider();
      const teacherValidation = await checkValidationForTeachers();
      const schoolValidation = await checkValidationForSchools();

      resolve({
        vTime: timeValidation,
        vProvider: providerValidation,
        vTeachers: teacherValidation,
        vSchools: schoolValidation
      })
    });
    return { promise, cancel: cancelFunc };
  }

  const checkValidationOfSelectedTime = async () => {
    const cResult = checkProviderWorkingTime(time, providerTimezone, earliestStartTime);
    const { isInWorkingTime, providerTime } = cResult;
    if (!isInWorkingTime) {
      return "invalid-working-" + providerTime;
    }

    if (isCalendarToken) {
      const providerId = provider.id;

      // Convert selectedTime to America/New_York timezone
      const options = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      };
      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(time);
      const formattedDate = `${parts[4].value}-${parts[0].value}-${parts[2].value}T${parts[6].value}:${parts[8].value}:${parts[10].value}`;

      const dateInfo = {
        day: formattedDate.split('T')[0],
        time: formattedDate.split('T')[1]
      };

      const calendarStatus = await Axios.get(`https://research.tigweb.org/connectednorth/provider-availability/events.html?provider=${providerId}&date=${dateInfo.day}&time=${dateInfo.time}`)
      if (calendarStatus.data === "No events found.") {
        return "valid";
      } else {
        return "invalid";
      }
    }
    return "not-applicable";
  }

  const checkValidationForProvider = async () => {
    const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
      time,
      sessionLength,
      'Provider',
      session["Provider Name Text"][0],
      session["Provider"][0]
    );

    if (!bookedSessionId) {
      if (sessions.length) {
        return "invalid";
      }
    } else {
      if (sessions.length > 1 || (sessions.length === 1 && sessions[0].id !== bookedSessionId)) {
        return "invalid";
      }
    }
    return "valid";
  }

  const checkValidationForTeachers = async () => {
    const checkTeacher = async (teacher) => {
      const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
        time,
        sessionLength,
        'Teacher',
        teacher["Teacher Name"],
        teacher.id
      );

      if (!bookedSessionId) {
        if (sessions.length) {
          return "invalid";
        }
      } else {
        if (sessions.length > 1 || (sessions.length === 1 && sessions[0].id !== bookedSessionId)) {
          return "invalid";
        }
      }
      return "valid";
    }

    const teacherRes = [];
    if (Array.isArray(teachers)) {
      for (let i = 0; i < teachers.length; i++) {
        const res = await checkTeacher(teachers[i]);
        teacherRes.push(res);
      }
    } else {
      const res = await checkTeacher(teachers);
      teacherRes.push(res);
    }
    return teacherRes;
  }

  const checkValidationForSchools = async () => {
    const checkSchool = async (school) => {
      if (!school["Block Simultaneous Bookings"]) return "not-applicable";
      const ss = await airtable.sessions.getSessionsForSchoolInSpecificPeriod(
        time,
        sessionLength,
        school["School"]
      );
      if (ss && ss.length) return "invalid";
      return "valid";
    }

    let schoolIds = [];
    if (Array.isArray(teachers)) {
      const ids = teachers.map(t => t["School Name"][0]);
      schoolIds = distinctArray(ids);
    } else {
      schoolIds = teachers["School Name"];
    }

    const schools = await airtable.schools.listByIds(schoolIds);
    const schoolRes = [];
    for (let i = 0; i < schools.length; i++) {
      const res = await checkSchool(schools[i]);
      schoolRes.push(res);
    }
    return schoolRes;
  }

  return {
    checking,
    validationData
  }
}

export default useHook;