import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const JoinSessionNotesModal = ({ isOpen, toggle, session, onJoin }) => {
  const { userType } = useSelector(state => state.appInfo);
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("session-notes")}</ModalHeader>
      <ModalBody>
        {session["Trigger Warning"] && userType === "Team" && (
          <div className="mb-4">
            <h6 className="font-weight-bold text-danger">Trigger Warning</h6>
            <p>This Session has been flagged as requiring a trigger warning at the beginning of the session. Please discuss this with the provider/class prior to the Session beginning.</p>
          </div>
        )}
        
        {userType === "Provider" ? (
          <>
            {session.Notes && (
              <>
                <h6 className="font-weight-bold">{t("provider-notes")}</h6>
                <p className="mb-4">{session.Notes}</p>
              </>
            )}
          </>
        ) : (
          <>
            {session.Notes && (
              <>
                <h6 className="font-weight-bold">{t("provider-notes")}</h6>
                <p className="mb-4">{session.Notes}</p>
              </>
            )}
            
            {session["Session Host Notes"] && (
              <>
                <h6 className="font-weight-bold">{t("session-host-notes")}</h6>
                <p className="mb-4">{session["Session Host Notes"]}</p>
              </>
            )}
          </>
        )}
        
        <Button 
          color="primary" 
          block 
          onClick={() => {
            onJoin();
            toggle();
          }}
        >
          {t("join-session")}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default JoinSessionNotesModal; 