
import AirtableTable from "../AirtableTable";

export class ClusterTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Clusters");

    this.fields = {
      "Name": "fldivuONn0DwV0oCg",
      "Schools": "fld9ZFlM4QbEjxEYy",
      "Education Authority Name": "fldmWnX91dXYLnTxd",
      "Default New Teacher Credits": "fldU9CisZKCzPwxHq"
    }
  }

  selectClusterByDomain = (domain) => {
    const dm = domain.toUpperCase();
    return this.selectOneByCondition({
      filterByFormula: `OR("${dm}" = UPPER({Domain}), "${dm}" = UPPER({Secondary Domain}), "${dm}" = UPPER({Tertiary Domain}))`
    });
  }

  selectBySchool = (schoolName) => {
    return this.selectOneByCondition({
      filterByFormula: `FIND(";${schoolName.toLowerCase()};", CONCATENATE(";", SUBSTITUTE(LOWER(ARRAYJOIN({Schools})), ",", ";"), ";"))`
    });
  }
}
