import React, { useState } from "react";
import "./styles.scss";
import { Button, FormGroup, Input, Label, Modal } from "reactstrap";
import { useSelector } from "react-redux";
import { CurrentGradeLevels } from "../../../utils/constants";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import * as EmailValidator from "email-validator";
import classNames from "classnames";
import { toast } from "react-toastify";

const EditTeacherModal = ({
  onToggle,
  teacher,
  onSave
}) => {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(teacher.name);
  const [email, setEmail] = useState(teacher.email);
  const [phone, setPhone] = useState(teacher.phone);
  const [subjects, setSubjects] = useState(teacher.subjects);
  const [grades, setGrades] = useState(teacher.grades);
  const [errors, setErrors] = useState({});
  const { totalSubjects } = useSelector(state => state.appInfo);
  const { t } = useTranslation();

  const toggleSubjects = (subject) => {
    if (saving) return;

    const pos = subjects.indexOf(subject.id);
    if (pos < 0) {
      const newSubs = [...subjects, subject.id];
      setSubjects(newSubs);
    } else {
      const newSubs = subjects.filter(item => item !== subject.id);
      setSubjects(newSubs);
    }
  }

  const toggleGrades = (grade) => {
    if (saving) return;

    const pos = grades.indexOf(grade);
    if (pos < 0) {
      const newGrades = [...grades, grade];
      setGrades(newGrades);
    } else {
      const newGrades = grades.filter(g => g !== grade);
      setGrades(newGrades);
    }
  }

  const saveTeacher = async () => {
    let validations = {};
    if (!name) validations.name = "This field is required";
    if (!email) validations.email = "This field is required";
    if (email && !EmailValidator.validate(email)) validations.email = "Invalid Email Address";
    if (!subjects || subjects.length === 0) validations.subjects = "Need to select at least one subject.";
    if (!grades || grades.length === 0) validations.grades = "Need to select at least one grade.";
    if (Object.keys(validations).length > 0) {
      setErrors(validations);
      return;
    }

    try {
      setSaving(true);
      await onSave(teacher.id, { name, email, phone, subjects, grades })
      onToggle();
    } catch(error) {
      toast.error(error.toString());
      setSaving(false);
    }
  }

  return (
    <Modal
      isOpen={true}
      className="modal-dialog-centered edit-teacher-modal"
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Edit Teacher</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            type="text"
            className="form-style"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            invalid={!!errors.name}
          />
          <div className="invalid-feedback">{errors.name}</div>
        </FormGroup>
        <FormGroup>
          <Label for="email">E-mail</Label>
          <Input
            type="text"
            className="form-style"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            invalid={!!errors.email}
          />
          <div className="invalid-feedback">{errors.email}</div>
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input
            type="text"
            className="form-style"
            name="phone"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            invalid={!!errors.phone}
          />
        </FormGroup>
        <FormGroup className="registering-item">
          <Label for="subjects">Subject(s) Taught</Label>
          <div className={classNames({ "is-invalid": errors.subjects ? true : false })}>
            <div className="flex-wrap-container">
              {
                totalSubjects
                  .filter(sub => sub.Subject !== "Premium Session" && sub.Subject !== "Therapy (SLP/OT)" && sub.Subject !== "Holiday Themed")
                  .map((sub, i) => (
                    <div
                      className={subjects.indexOf(sub.id) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                      key={i}
                      onClick={() => toggleSubjects(sub)}
                    ><span>{sub.Subject}</span></div>
                  ))
              }
            </div>
          </div>
          <div className="invalid-feedback">{errors.subjects}</div>
        </FormGroup>

        <FormGroup className="registering-item">
          <Label for="grades">Grade(s) Taught</Label>
          <div className={classNames({ "is-invalid": errors.grades ? true : false })}>
            <div className="flex-wrap-container">
              {
                CurrentGradeLevels.map((grade, i) => (
                  <div
                    className={grades.indexOf(grade) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                    key={i}
                    onClick={() => toggleGrades(grade)}
                  ><span>{grade}</span></div>
                ))
              }
            </div>
          </div>
          <div className="invalid-feedback">{errors.grades}</div>
        </FormGroup>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          type="button"
          onClick={saveTeacher}
        >
          {saving ? (<Loading size={14} />) : (null)}{t("save")}
        </Button>
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {t("close")}
        </Button>
      </div>
    </Modal>
  )
}

export default EditTeacherModal;