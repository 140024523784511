import SessionInfo from "../../../components/SessionInfo";
import { getDisplayInfos } from "../../SessionInfoDisplay";
import CarouselProvider from "../../../components/Carousel";

const responsiveSessions = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 2000 },
		items: 1,
	},
	desktop: {
		breakpoint: { max: 2000, min: 1024 },
		items: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

/**
 * @param {Object} props
 * @param {any[]} props.sessions
 */

export function Sessions({ sessions }) {

	// If there are no sessions, do not render the section
	if (sessions.length === 0) {
		return null;
	}

	const renderSessions = () => {
		return sessions.map((s) => (
			<SessionInfo
				displayInfos={getDisplayInfos(s, 'Session-Page')}
				sessionDetailLink={s.id}
				key={s.id}
				session={s}
				hideProvider={true}
				actionMenu={[
					"copy-provider-session-link",
					"open-detail",
				  ]}
			/>
		));
	};

	return (
		<CarouselProvider
			responsive={responsiveSessions}
			infinite={false}
			title={`${sessions.length} Session(s) Offered`}
		>
			{renderSessions()}
		</CarouselProvider>
	);
}
