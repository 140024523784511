import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import useHook from "./use-hook";
import { useTranslation } from "react-i18next";
import { getJustTimeZone } from "../../pages/modals/Request/utils";
import Loading from "../Loading";
import { useSelector } from "react-redux";

const SessionValidationChecker = ({
  time,
  session,
  provider,
  teachers,
  bookedSessionId,
  changeValidation
}) => {
  const { userType } = useSelector(state => state.appInfo);
  const {
    checking,
    validationData
  } = useHook({ time, session, provider, teachers, bookedSessionId });
  const { t } = useTranslation();
  const pTimezone = getJustTimeZone(session["Provider Timezone"][0]);
  const earliestStartStandardTime = session["Provider Earliest Start Time"] ? session["Provider Earliest Start Time"][0] : "8:30";

  const checkTotalValidation = () => {
    if (checking) return "checking";
    if (!validationData) return "invalid";
    if (validationData.vTime !== "valid" && validationData.vTime !== "not-applicable") return "invalid";
    if (validationData.vProvider !== "valid") return "invalid";
    if (!validationData.vTeachers || validationData.vTeachers.indexOf("invalid") >= 0) return "invalid";
    return "valid";
  }

  useEffect(() => {
    changeValidation(checkTotalValidation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking, validationData])

  if (checking) {
    return (
      <div>
        <Loading size={14} />
        <span className="lbl">{` `}Checking validation...</span>
      </div>
    )
  }

  if (!validationData) return null;

  const { vTime, vProvider, vTeachers, vSchools } = validationData;

  const renderTimeValidationItem = () => {
    if (vTime.startsWith('invalid-working-')) {
      const providerTime = vTime.substring(16);

      return (
        <span className={styles.invalid}>
          {`The provider is in the ${pTimezone} Time Zone and this is ${providerTime} their time. Please request a time after ${earliestStartStandardTime} AM.`}
        </span>
      )
    }

    if (vTime === 'valid') {
      return (
        <span className={styles.valid}>{t("sessionrequest.time-valid-msg")}</span>
      )
    }

    if (vTime === 'invalid') {
      return (
        <span className={styles.invalid}>{t("sessionrequest.time-invalid-msg")}</span>
      )
    }
    return null;
  }

  const renderProviderValidation = () => {
    if (!vProvider) return null;

    if (vProvider === "valid") {
      return (
        <div className={styles.validationItem}>
          <div className={styles.check}>
            <i className="fa fa-check" />
          </div>
          <span className={styles.valid}>Provider has no overlapping Sessions</span>
        </div>
      )
    } else if (vProvider === "invalid") {
      return (
        <div className={styles.validationItem}>
          <div className={styles.close}>
            <i className="fa fa-check" />
          </div>
          <span className={styles.invalid}>Sorry, the provider is already booked for another Session at this time.</span>
        </div>
      )
    }
    return null;
  }

  const renderTeachersValidation = () => {
    if (!vTeachers) return null;

    const invalids = vTeachers.filter(t => t === "invalid");

    const getSubject = () => {
      if (invalids.length === 1) {
        if (Array.isArray(teachers)) return "Teacher is";
        return "Teacher is";
      } else {
        return "Some teachers are"
      }
    }

    if (invalids.length > 0) {
      return (
        <div className={styles.validationItem}>
          <div className={styles.close}>
            <i className="fa fa-close" />
          </div>
          <span className={styles.invalid}>{getSubject()} booked for another Session at this time!</span>
        </div>
      )
    } else {
      if (userType !== "Team") return null;
      return (
        <div className={styles.validationItem}>
          <div className={styles.check}>
            <i className="fa fa-check" />
          </div>
          <span className={styles.valid}>Teacher has no overlapping Sessions</span>
        </div>
      )
    }
  }

  const renderSchoolsValidation = () => {
    if (!vSchools) return null;

    const invalids = vSchools.filter(t => t === "invalid");
    const valids = vSchools.filter(t => t === "valid");

    const getSubject = () => {
      if (invalids.length === 1) {
        return "another teacher at the School";
      } else {
        return "another teacher at one of the Schools"
      }
    }

    if (invalids.length > 0) {
      return (
        <div className={styles.validationItem}>
          <div className={styles.close}>
            <i className="fa fa-close" />
          </div>
          <span className={styles.invalid}>Sorry, {getSubject()} is already booked for another Session at this time.</span>
        </div>
      )
    } else if (valids.length > 0) {
      if (userType !== "Team") return null;
      return (
        <div className={styles.validationItem}>
          <div className={styles.check}>
            <i className="fa fa-check" />
          </div>
          <span className={styles.valid}>School{valids.length > 1 ? "s" : ""} has no overlapping Sessions</span>
        </div>
      )
    }
    return null;
  }

  return (
    <div>
      {!!vTime && renderTimeValidationItem()}
      {renderProviderValidation()}
      {renderTeachersValidation()}
      {renderSchoolsValidation()}
    </div>
  )
}

export default SessionValidationChecker;